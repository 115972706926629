import { NormalTypes } from "../../lib/util-types";
import React, { useMemo } from "react";

interface Props {
  type?: NormalTypes;
  label?: string | boolean;
  small?: boolean;
  filled?: boolean;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type NoteProps = Props & NativeAttrs;

const getStatusColor = (type: NormalTypes, filled: boolean) => {
  const textColors: { [key in NormalTypes]?: string } = {
    secondary: "text-secondary",
    primary: "text-primary",
    warning: "text-yellow-500",
    error: "text-red-500",
  };
  const borderColors: { [key in NormalTypes]?: string } = {
    secondary: "border-secondary",
    primary: "border-primary",
    warning: "border-gray-50",
    error: "border-red-500",
  };
  const bgColors: { [key in NormalTypes]?: string } = {
    secondary: "bg-secondary",
    primary: "bg-primary",
    warning: "bg-yellow-500 dark:bg-yellow-800",
    error: "bg-red-900",
  };
  const textStatusColor = textColors[type];
  const borderStatusColor = borderColors[type];
  const bgStatusColor = bgColors[type];

  if (!filled)
    return {
      color: textStatusColor || "text-foreground",
      borderColor: borderStatusColor || "border-gray-200",
      bgColor: "bg-transparent",
    };
  const filledColor = textStatusColor ? "text-foreground" : "text-background";
  return {
    color: filledColor,
    borderColor: borderStatusColor || "border-foreground",
    bgColor: bgStatusColor || "bg-foreground",
  };
};

export const Note: React.FC<React.PropsWithChildren<NoteProps>> = ({
  children,
  type = "default" as NormalTypes,
  label = "Note" as string | boolean,
  small = false,
  filled = false,
  className = "",
  ...props
}) => {
  const { color, borderColor, bgColor } = useMemo(
    () => getStatusColor(type, filled),
    [type, filled]
  );

  return (
    <div
      className={`note text-sm leading-relaxed border rounded-md ${
        small ? "py-1 px-2" : "py-2 px-4"
      } ${color} ${borderColor} ${bgColor} ${className}`}
      {...props}
    >
      {label && (
        <span className="label uppercase select-none leading-normal pr-1">
          <b>{label}:</b>
        </span>
      )}
      {children}
    </div>
  );
};

export default Note;
